
.colorsContainer
  z-index: 3
  display: flex
  flex-wrap: wrap
  justify-content: space-evenly
  padding: 0 0.5em
  &:last-child
    margin-bottom: 2em
  .color
    min-height: 3.3vw
    margin: 1em 0
    text-align: center
    border-radius: 2vw 20vw
    @media screen and (max-width: 520px)
      margin: 0.3em 0
    & > svg
      transform: rotate(90deg)
