$border-width: 0.5em

@keyframes fadein
  0%
    opacity: .2
  100%
    opacity: 1

label
  font-size: 1.1em
  @media screen and (max-width: 820px)
    font-size: 0.75em

.expandedOnly
  transform: scale(1)
  transition: all 3.4s, opacity 0.8s, top 0.2s
  opacity: 1

.wrapper
  max-height: 0
  transition: max-height 0.4s, border-width 1s, border-color 2s
  display: flex
  width: 100%
  background: inherit
  &:not(.expanded)
    overflow: hidden
  &.expanded
    max-height: 10em
    overflow: auto
    @media screen and (max-width: 820px)
      max-height: 20em
    & .afterDiv
      position: absolute
      height: 100%
      width: 100%
      content: " "
      pointer-events: none
      border: 0.5em double gray

.value
  font-weight: bold

.column
  padding: 1em 0.25em
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border-bottom: $border-width double gray
  border-right: $border-width double gray
  width: calc(100% - 4em)
  @media screen and (min-width: 820px)
    &:last-child
      border-right: 0

  @media screen and (max-width: 820px)
    padding: 0
    padding-left: $border-width
    &:first-child
      padding-top: $border-width
    justify-content: space-between
    flex-direction: row
    input
      width: 4em
      cursor: pointer

.settingsContainer
  display: flex
  align-items: stretch
  width: 100%
  z-index: 1
  border: $border-width solid transparent
  border-bottom: 0
  @media screen and (max-width: 820px)
    flex-direction: column
  .setting
    display: flex
    justify-content: space-evenly
    align-items: center
    justify-content: center
    align-items: stretch
    @media screen and (max-width: 820px)
      min-width: 2em
      width: unset

.expander
  width: 3em
  height: 3em
  position: absolute
  top: -1.25em
  padding: 1em
  transition: background 1s
  right: -1.25em
  z-index: 4
  svg
    margin: auto
  cursor: pointer

.container
  width: 100%
  z-index: 0

  label
    .column
      padding: 1em 0

    .expander
      top: -0.5em
      right: -0.5em
      width: 3em
      height: 3em
      opacity: 1
      display: flex
      justify-content: center
      align-items: center
      svg
        margin: auto
    @media screen and (max-width: 820px)
      top: 0
      right: 0
      padding: 0.4em
      background: none

  &.expanded
    & > .settingsContainer
      margin: auto
      & > *
        opacity: 1
        padding: 2em 3em
        @media screen and (max-width: 820px)
          padding: 0.25em

  &.collapsed
    & > .settingsContainer
      margin-left: auto
      margin-right: 5em
    & .expandedOnly
      transform: scale(0)
      opacity: 0
      &.column
        height: 0
        width: 0

    & .setting
      width: 0px
      height: 0px

  &:hover .expander
    opacity: 1
    transition: top 0.7s

.icon
  width: 1em
  height: 1em

.paletteFromSwatchButton
  position: absolute
  top: 0
  left: 0
  // left: calc(50% - 0.5em)

.newPaletteAreaHighlight
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none
    .paletteFromSwatchButton:hover + &
      border: 1px solid white
      