
header
  .colorName
    padding: 1em auto
    position: absolute
    width: 100%
    @media screen and (max-width: 520px)
      padding: 0.75em 0
  //  margin: 2em 0

.palettesSubwindow
  display: flex
  flex-wrap: wrap
  & > div
    flex-grow: 1
    margin: 1em
    @media screen and (max-width: 520px)
      margin: 0.5em

.paletteDisplay
  margin-top: 2em
  position: relative
  text-align: center

.paletteDisplayContainer
  position: relative
  text-align: center
  margin: 1em 0.25em
  .shown
    max-height: 100000px
    transition: max-height 20s

.paletteDisplayHidden
  font-weight: 100
  text-align: center
  transform: scale(0.0015)
  position: relative
  // box-shadow: rgba(255, 255, 255, 0.7) 3px 3px 30px 10px
  opacity: 0.7

  &.paletteDisplayShow
    transform: scale(1)
    transition: transform 1.6s, top 1.2s, left 1.2s, opacity 0.2s
    font-weight: 300
    top: 0 !important
    left: 0 !important
    // box-shadow: 0 0 0 0 !important
    opacity: 1
//  transition: <property> <duration> <timing-function> <delay>;

.displayTypeContainer
  display: flex
  justify-content: center
  max-width: 20em
  flex-grow: 5
  margin: auto
  p
    width: 20em
    max-width: 50vw
  &:hover, &:active
    button
      &.next, &.previous
        opacity: 1
        border: 1px

    // position: absolute
    // top: 50%
  &.previous
    left: 0
  &.next
    right: 0

@media screen and (max-width: 640px)
  .palettesMainwindow
    width: 100%
    position: relative

  .colorSwatchGradientContainer
    padding: 1em 0

// animation-name: paletteDisplay-grow
// @keyframes paletteDisplay-grow
//   0%
//     position: absolute
//     font-weight: 100
//     transform: scale(0)
//   8%
//     transform: scale(0.2)
//   50%
//     transform: scale(0.95)
//   80%
//     font-weight: 300
//     top: 200vh
//   100%
//     font-weight: auto
//     position: relative
//     transform: scale(1)
