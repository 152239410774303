.colorSwatch
  display: blocḱ
  position: relative
  height: 6em
  min-width: 6em
  max-width: 12em
  font-size: 0.85em
  margin: 2em 1em
  @media screen and (max-width: 520px)
    font-size: 0.75em
    margin: 1.5em 1em
  @media screen and (min-width: 960px)
    min-width: 10em
    font-size: 0.85em
    margin: 1.5em 1em
    flex-shrink: 1
  @media screen and (min-width: 1920px)
    min-width: 10em
    font-size: 1em
    margin: 1.5em 1em
    flex-shrink: 0

  p
    position: absolute
    margin: 0
    bottom: 0
    left: 0.16em
    text-align: left
