.toolbar
  display: flex
  justify-content: space-around
  position: sticky
  top: 0
  padding: 1em
  background: #eee
  z-index: 1000

  & > *
    &:first-child
      border-left: 1px dashed gray
    border-right: 1px dashed gray
  @media screen and (max-width: 520px)
    padding: 0

  input, select, button
    min-height: 2em
    margin: auto 0
    cursor: pointer
    flex-grow: 1
  input[type="number"]
    text-align: right

  label
    height: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin: auto
    padding: 0 1em
    flex-grow: 1
    @media screen and (max-width: 520px)
      padding: 0
      min-height: 5em
      & > *
        padding: 0
        margin: 0
    p
      margin: auto 1em auto 0.25em
      white-space: nowrap
      @media screen and (max-width: 520px)
        white-space: initial
        text-align: center
        padding: 0
        margin: auto

